<template>
  <modal className="w-full sm:px-8 sm:w-443px" ref="vendorBranchModal">
    <transition-group name="slide">
      <select-branch
        v-if="step === 'select-branch'"
        :vendor="vendor"
        v-on="listeners"
        :key="componentKey"
      />

      <branch-details
        v-if="step === 'confirm-branch'"
        :selected-branch="selectedBranch"
        v-on="navigate"
        ref="branch-details"
        :key="componentKey"
      />

      <payment-method
        v-if="step === 'payment-method'"
        v-bind="attrs"
        v-on="navigate"
        ref="payment-method"
        :key="componentKey"
      />

      <component
        v-if="step === 'method-information'"
        :is="paymentMethod"
        v-bind="attrs"
        v-on="navigate"
        :key="componentKey"
      />
    </transition-group>
  </modal>
</template>
<script>
export default {
  components: {
    BranchDetails: () => import("./BranchDetails"),
    SelectBranch: () => import("./SelectBranch"),
    PaymentMethod: () => import("../payment/Index"),
    CreditCard: () => import("../payment/CreditCard"),
    LoanLimit: () => import("../payment/Loan")
  },
  props: {
    vendor: {
      type: [Object, String],
      required: true
    }
  },
  data() {
    return {
      selectedBranch: null,
      step: "select-branch",
      componentKey: 0,
      paymentMethod: null,
      order: null
    };
  },
  computed: {
    listeners() {
      return {
        selectBranch: branch => this.selectBranch(branch)
      };
    },
    navigate() {
      return {
        goBack: step => this.goBack(step),
        proceed: step => this.proceed(step),
        setPaymentMethod: method => (this.paymentMethod = method),
        setOrderDetails: order => (this.order = order),
        confirmOrder: data => this.confirmOrder(data)
      };
    },
    billingDate() {
      return this.$moment(
        this.user?.personal_statement?.extra?.payment_due_date
      ).format("MMM Do, YYYY");
    },
    attrs() {
      return {
        ...this.$attrs,
        vendor: this.vendor,
        order: this.order,
        billing_date: this.billingDate
      };
    }
  },
  mounted() {
    this.$refs.vendorBranchModal.open();

    this.$watch("selectedBranch.name", () => {
      this.componentKey += 1;
    });
  },
  methods: {
    close() {
      this.$refs.vendorBranchModal.close();
    },
    open() {
      this.$refs.vendorBranchModal.open();
    },
    goBack(route) {
      return (this.step = route);
    },
    success() {
      this.$emit("success");
    },
    proceed(step) {
      if (!this.validateUser("onboarding")) {
        this.close();
        this.resumeOnboarding();
        return;
      }
      switch (step) {
        case "payment":
          return this.proceedToPayment();
        case "payment-method":
        case "method-information":
          return this.$nextTick(() => {
            this.step = step;
          });
        default:
      }
    },
    confirmOrder(data) {
      switch (this.paymentMethod) {
        case "credit-card":
          return this.payWithCreditCard(data);
        case "loan-limit":
          return this.spreadPayment(data);
        default:
          return null;
      }
    },
    async payWithCreditCard({ amount, closeLoading }) {
      await this.sendRequest("personal.marketplace.placeOrder", {
        data: {
          branch_id: this.selectedBranch?.id,
          merchant_id: this.vendor?.id,
          item: this.order?.item?.value,
          amount: amount
        },
        success: () => {
          this.$success({
            title: "Payment Confirmed",
            body: "Your order has been successfully placed",
            button: "Okay",
            onClose: () => this.close()
          });
        },
        error: error => {
          this.$error({ title: `${error?.response?.data?.message}` });
        }
      });
      closeLoading();
    },
    async spreadPayment({
      authorizationCode,
      reference,
      tenure,
      method,
      closeLoading
    }) {
      let data = {
        branch_id: this.selectedBranch?.id,
        merchant_id: this.vendor?.id,
        item: this.order?.item?.value,
        amount: this.order?.amount?.value,
        tenure: tenure
      };
      switch (method) {
        case "repayment-card":
          data = { ...data, authorization_code: authorizationCode };
          break;
        case "new-card":
          data = { ...data, reference: reference };
          break;
        default:
      }
      await this.sendRequest("personal.marketplace.spreadPayment", {
        data: data,
        success: () => {
          this.$success({
            title: "Payment Confirmed",
            body: "Your order has been successfully placed",
            button: "Go to purchase history",
            onClose: () => this.$router.push({ name: "order-history" })
          });
        },
        error: error => {
          this.$error({
            title: `${error?.response?.data?.message}`
          });
        }
      });
      closeLoading();
    },
    selectBranch(branch) {
      this.selectedBranch = {
        ...branch,
        image: this.vendor?.image,
        vendor: this.vendor
      };
      this.step = "confirm-branch";
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slide-enter-active {
  @include animated;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.popIn {
  @include animated;
  -webkit-animation-name: pop-in;
  animation-name: pop-in;
}

.slideInRight {
  @include animated;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes slide {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slide {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
